import React from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";

const Disclaimer = () => {
  return (
    <div>
      <Header />
      <div className="text-3xl font-semibold text-center py-72">
        Coming Soon..
      </div>
      <Footer />
    </div>
  );
};

export default Disclaimer;
