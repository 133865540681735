import axios from "axios";

export const loginAction = async (payload) => {
  const response = await axios.post(
    `${process.env.REACT_APP_BASE_URL}/user/login`,
    payload,
    {
      headers: {
        "Content-Type": "application/json",
      },
      withCredentials: true,
    }
  );

  return response.data;
};

export const accessTokenAction = async (payload) => {
  const response = await axios.post(
    `${process.env.REACT_APP_BASE_URL}/user/access_Token`,
    null,
    {
      withCredentials: true,
    }
  );

  return response.data;
};

export const logoutAction = async (payload) => {
  const response = await axios.get(
    `${process.env.REACT_APP_BASE_URL}/user/logout`
  );

  localStorage.removeItem("firstlogin");
  return response;
};

export const getUserDetailsAction = async (token) => {
  const response = await axios.get(
    `${process.env.REACT_APP_BASE_URL}/user/getUserInformation`,
    {
      withCredentials: true,
      headers: {
        Authorization: token,
      },
    }
  );

  return response.data;
};
