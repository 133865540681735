import React, { useEffect, useState } from "react";
import phauzdarLogo from "../assets/phouzdar_logo.png";
import { useDispatch, useSelector } from "react-redux";
import ArtItem from "../components/ArtItem";

const AdminPage = () => {
  const dispatch = useDispatch();
  const formdata = new FormData();
  const [isEdit, setisEdit] = useState(false);
  const [isnonselectEdit, setisnonselectEdit] = useState(false);
  const [editArtDetail, seteditArtDetail] = useState({});

  const { arts, nonselectedArts, isuploading } = useSelector(
    (state) => state.art
  );
  console.log(isuploading, "isuploading");
  const [email, setemail] = useState();
  const [password, setpassword] = useState();
  const [authMsg, setauthMsg] = useState();

  const [newPicture, setnewPicture] = useState();
  const [artDeatil, setartDeatil] = useState({
    year: "",
    height: "",
    width: "",
    description: "",
    Price: "",
  });

  const { token } = useSelector((state) => state.auth);

  formdata.append("picture", newPicture);
  formdata.append("year", artDeatil?.year);
  formdata.append("height", artDeatil?.height);
  formdata.append("width", artDeatil?.width);
  formdata.append("description", artDeatil?.description);
  formdata.append("Price", artDeatil?.Price);

  console.log(arts, "arts");
  console.log(nonselectedArts, "arts non selected");

  const login = (e) => {
    dispatch({
      type: "LOGIN_USER",
      payload: {
        email: email,
        password: password,
      },
    });
  };

  console.log(editArtDetail, "edit art detail");

  return (
    <div className="px-10 py-5">
      <div>
        <img src={phauzdarLogo} alt="" />
        <div className="mt-20 mb-5 text-lg font-semibold">Admin View</div>
        {authMsg && <div>{authMsg}</div>}
      </div>

      {/* login  */}
      {!token && (
        <div className="px-3 py-10 rounded-lg bg-slate-200 w-96">
          <div>
            <div className="flex">
              <div className="w-28">
                <label className="text-lg font-semibold text-gray-500">
                  Email
                </label>
              </div>
              <input
                type="text"
                name=""
                value={email}
                onChange={(e) => {
                  setemail(e.target.value);
                }}
                className="bg-transparent border-b rounded-md border-amber-700 focus:outline-none"
              />
            </div>
            <div className="flex mt-3">
              <div className="w-28">
                <label className="text-lg font-semibold text-gray-500">
                  Password
                </label>
              </div>
              <input
                type="password"
                name=""
                value={password}
                className="bg-transparent border-b rounded-md border-amber-700 focus:outline-none"
                onChange={(e) => {
                  setpassword(e.target.value);
                }}
              />
            </div>
            <div className="flex mt-3">
              <div className="w-28"></div>
              <button
                onClick={login}
                className="px-20 py-1 mt-3 text-white bg-green-700 rounded-md"
              >
                Login
              </button>
            </div>
          </div>
        </div>
      )}

      {token && (
        <div className="flex justify-between pt-10">
          <div className="">
            {/* new art upload */}
            {!isEdit ? (
              <div className="w-40 text-lg font-semibold text-gray-500 border-b ">
                Add New Art
              </div>
            ) : (
              <div className="w-40 text-lg font-semibold text-gray-500 border-b ">
                Edit Art
              </div>
            )}
            <div className="pt-3 space-y-2">
              <div>
                <div className="text-sm">Choose art image</div>
                <div className="flex items-center space-x-2">
                  {!isEdit ? (
                    <input
                      type="file"
                      onChange={(e) => {
                        setnewPicture(e.target.files[0]);
                      }}
                    />
                  ) : (
                    <img src={editArtDetail?.art} alt="" className="w-52" />
                  )}
                  {newPicture && (
                    <div>
                      <div>Preview Image</div>
                      <img
                        src={newPicture && URL.createObjectURL(newPicture)}
                        alt=""
                        className="rounded-sm w-52"
                      />
                    </div>
                  )}
                </div>
              </div>

              <div className="pt-10">
                <div className="text-sm">The year the art was created</div>
                {!isEdit ? (
                  <input
                    type="text"
                    name=""
                    value={artDeatil?.year}
                    onChange={(e) => {
                      setartDeatil({
                        ...artDeatil,
                        year: e.target.value,
                      });
                    }}
                    className="border-b border-amber-700 focus:outline-none"
                  />
                ) : (
                  <input
                    type="text"
                    name=""
                    value={editArtDetail?.year}
                    onChange={(e) => {
                      seteditArtDetail({
                        ...editArtDetail,
                        year: e.target.value,
                      });
                    }}
                    className="border-b border-amber-700 focus:outline-none"
                  />
                )}
              </div>

              <div>
                <div className="text-sm">Width of the picture</div>
                {!isEdit ? (
                  <input
                    type="text"
                    value={artDeatil?.width}
                    onChange={(e) => {
                      setartDeatil({
                        ...artDeatil,
                        width: e.target.value,
                      });
                    }}
                    className="border-b border-amber-700 focus:outline-none"
                  />
                ) : (
                  <input
                    type="text"
                    value={editArtDetail?.width}
                    onChange={(e) => {
                      seteditArtDetail({
                        ...editArtDetail,
                        width: e.target.value,
                      });
                    }}
                    className="border-b border-amber-700 focus:outline-none"
                  />
                )}
              </div>
              <div>
                <div className="text-sm">Height of the picture</div>
                {!isEdit ? (
                  <input
                    type="text"
                    value={artDeatil?.height}
                    onChange={(e) => {
                      setartDeatil({
                        ...artDeatil,
                        height: e.target.value,
                      });
                    }}
                    className="border-b border-amber-700 focus:outline-none"
                  />
                ) : (
                  <input
                    type="text"
                    value={editArtDetail?.height}
                    onChange={(e) => {
                      seteditArtDetail({
                        ...editArtDetail,
                        height: e.target.value,
                      });
                    }}
                    className="border-b border-amber-700 focus:outline-none"
                  />
                )}
              </div>

              <div>
                <div className="text-sm">Description (if any)</div>
                {!isEdit ? (
                  <input
                    type="text"
                    value={artDeatil?.description}
                    onChange={(e) => {
                      setartDeatil({
                        ...artDeatil,
                        description: e.target.value,
                      });
                    }}
                    className="border-b border-amber-700 focus:outline-none"
                  />
                ) : (
                  <input
                    type="text"
                    value={editArtDetail?.description}
                    onChange={(e) => {
                      seteditArtDetail({
                        ...editArtDetail,
                        description: e.target.value,
                      });
                    }}
                    className="border-b border-amber-700 focus:outline-none"
                  />
                )}
              </div>
              <div>
                <div className="text-sm">Price</div>
                {!isEdit ? (
                  <input
                    type="number"
                    value={artDeatil?.Price}
                    onChange={(e) => {
                      setartDeatil({
                        ...artDeatil,
                        Price: e.target.value,
                      });
                    }}
                    className="border-b border-amber-700 focus:outline-none"
                  />
                ) : (
                  <input
                    type="number"
                    value={editArtDetail?.price}
                    onChange={(e) => {
                      seteditArtDetail({
                        ...editArtDetail,
                        price: e.target.value,
                      });
                    }}
                    className="border-b border-amber-700 focus:outline-none"
                  />
                )}
              </div>
              {isEdit ? (
                <div className="flex space-x-3">
                  <div
                    onClick={() => {
                      if (!isnonselectEdit) {
                        dispatch({
                          type: "UPDATE_ART",
                          payload: {
                            id: editArtDetail?.id,
                            body: {
                              height: editArtDetail?.height,
                              width: editArtDetail?.width,
                              description: editArtDetail?.description,
                              Price: parseFloat(editArtDetail?.price),

                              year: editArtDetail?.year,
                            },
                          },
                          token,
                        });
                        setisEdit(false);
                      }
                      if (isnonselectEdit) {
                        dispatch({
                          type: "UPDATE_NONSELECT_ART",
                          payload: {
                            id: editArtDetail?.id,
                            body: {
                              height: editArtDetail?.height,
                              width: editArtDetail?.width,
                              description: editArtDetail?.description,
                              Price: parseFloat(editArtDetail?.price),

                              year: editArtDetail?.year,
                            },
                          },
                          token,
                        });
                        setisEdit(false);
                        setisnonselectEdit(false);
                      }
                    }}
                    className="px-2 py-1 mt-3 font-semibold text-white bg-green-700 rounded-md cursor-pointer w-52"
                  >
                    update
                  </div>
                  <div
                    onClick={() => {
                      setisEdit(false);
                    }}
                    className="px-2 py-1 mt-3 font-semibold text-white bg-red-700 rounded-md cursor-pointer w-52"
                  >
                    Close
                  </div>
                </div>
              ) : (
                <div>
                  {!isuploading ? (
                    <>
                      <div
                        onClick={() => {
                          dispatch({
                            type: "CREATE_POST",
                            payload: {
                              body: formdata,
                              token,
                            },
                          });
                          setartDeatil({
                            year: "",
                            height: "",
                            width: "",
                            description: "",
                            Price: "",
                          });
                          setnewPicture();
                        }}
                        className="px-2 py-1 mt-3 font-semibold text-white bg-green-700 rounded-md cursor-pointer w-52"
                      >
                        Create for Select Art
                      </div>
                      <div
                        onClick={() => {
                          dispatch({
                            type: "CREATE_NONSELECT_ART",
                            payload: {
                              body: formdata,
                              token,
                            },
                          });
                          setartDeatil({
                            year: "",
                            height: "",
                            width: "",
                            description: "",
                            Price: "",
                          });
                          setnewPicture();
                        }}
                        className="px-2 py-1 mt-3 font-semibold text-white bg-green-700 rounded-md cursor-pointer w-52"
                      >
                        Create for Non Select Art
                      </div>
                    </>
                  ) : (
                    <div className="px-2 py-1 italic font-semibold text-white bg-green-700 cursor-pointer w-min">
                      Creating...
                    </div>
                  )}
                </div>
              )}
            </div>
          </div>
          {/* list of art */}

          <div className="grid grid-cols-3 overflow-y-auto gap-x-5 ">
            {arts?.map((art) => (
              <>
                <ArtItem
                  isNonSelect={false}
                  onclickedit={() => {
                    setisEdit(true);
                    seteditArtDetail({
                      id: art?._id,

                      artId: art?.artId,
                      height: art?.height,
                      width: art?.width,
                      description: art?.description,
                      price: art?.Price,
                      art: art?.picture?.secure_url,
                      year: art?.year,
                    });
                  }}
                  id={art?._id}
                  text={art?.description}
                  artId={art?.artId}
                  artsizeHeight={art?.height}
                  artsizewidth={art?.width}
                  art={art?.picture?.secure_url}
                  price={art?.Price}
                />
              </>
            ))}
            {nonselectedArts?.map((art) => (
              <>
                <div className="relative">
                  <span className="absolute z-20 px-3 mx-3 border border-gray-200 rounded-sm -top-36">
                    Non selected
                  </span>
                  <ArtItem
                    isNonSelect={true}
                    onclickedit={() => {
                      setisnonselectEdit(true);
                      setisEdit(true);
                      seteditArtDetail({
                        id: art?._id,

                        artId: art?.artId,
                        height: art?.height,
                        width: art?.width,
                        description: art?.description,
                        price: art?.Price,
                        art: art?.picture?.secure_url,
                        year: art?.year,
                      });
                    }}
                    id={art?._id}
                    text={art?.description}
                    artId={art?.artId}
                    artsizeHeight={art?.height}
                    artsizewidth={art?.width}
                    art={art?.picture?.secure_url}
                    price={art?.Price}
                  />
                </div>
              </>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default AdminPage;
