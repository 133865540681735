import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  arts: undefined,
  nonselectedArts: undefined,
  artDetail: {},
  isuploading: false,
  buyOriginalType: undefined,
  isLoading: false,
  isSendingDetail: false,
  isArtListLoading: false,
  priceToBeChekout: 0,
};

const artSlice = createSlice({
  name: "art",
  initialState,
  reducers: {
    setNonSelectedArts(state, action) {
      state.nonselectedArts = action.payload.nonselectedArts;
    },
    setPriceTobeCheckout: (state, action) => {
      state.priceToBeChekout = action.payload?.priceToBeChekout;
    },
    setIsArtListLoading(state, action) {
      state.isArtListLoading = action.payload.isArtListLoading;
    },
    setIsSendingDetail(state, action) {
      state.isSendingDetail = action.payload.isSendingDetail;
    },
    setIsLoading(state, action) {
      state.isLoading = action.payload.isLoading;
    },
    setBuyOriginalType(state, action) {
      state.buyOriginalType = action.payload.buyOriginalType;
    },
    setIsUploading(state, action) {
      state.isuploading = action.payload.isuploading;
    },
    getAllarts(state, action) {
      state.arts = action.payload.arts;
    },
    getArtDetails(state, action) {
      state.artDetail = action.payload.artDetail;
    },
  },
});

export const {
  setNonSelectedArts,
  getAllarts,
  getArtDetails,
  setIsUploading,
  setBuyOriginalType,
  setIsLoading,
  setIsSendingDetail,
  setIsArtListLoading,
  setPriceTobeCheckout,
} = artSlice.actions;
export const artSliceReducer = artSlice.reducer;
