import React, { useEffect, useState } from "react";
import Header from "../components/Header";
import { useDispatch, useSelector } from "react-redux";
import { CreditCard, PaymentForm } from "react-square-web-payments-sdk";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowAltCircleLeft } from "@fortawesome/free-regular-svg-icons";
import { Link, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { faEnvelope, faInfo } from "@fortawesome/free-solid-svg-icons";
import { Button, Tooltip } from "@chakra-ui/react";
import square_logo from '../assets/square_logo.png';
import SquareLogoPoweredBy from '../assets/powered-by-square-logo.png';

const ChekoutPage = () => {
  const dispatch = useDispatch();
  const { artDetail, buyOriginalType, priceToBeChekout } = useSelector(
    (state) => state.art
  );
  const { id } = useParams();
  console.log(buyOriginalType, "buyOriginalType");
  const [fullName, setfullName] = useState();
  const [address, setaddress] = useState();
  const [contactNumber, setcontactNumber] = useState();
  const [email, setEmail] = useState();

  useEffect(() => {
    dispatch({
      type: "ART_DETAIL",
      payload: {
        artId: id,
      },
    });
  }, []);

  const sendMailForOriginalArt = () => {
    const payload = {
      customerName: fullName,
      customerAddress: address,
      contactNo: contactNumber,
      customerEmail: email,
      artid: artDetail?.artId,
      artPrice: artDetail.Price,
      originalImage: artDetail?.picture?.secure_url,
    };
    setfullName(" ");
    setaddress(" ");
    setcontactNumber(" ");
    setEmail(" ");

    dispatch({
      type: "ORIGINAL_ART_MAIL",
      payload,
    });
  };

  return (
    <div className="w-full">
      <Header />
      <div className="py-10 ">
        <div className="flex items-center justify-between mx-10 ">
          <Link to={`/${id}`}>
            <FontAwesomeIcon
              icon={faArrowAltCircleLeft}
              className="w-5 h-5 md:w-7 md:h-7"
            />
          </Link>
          <div className="px-3 text-2xl font-thin text-left text-gray-500 md:text-4xl">
            Checkout{" "}
          </div>
          <div></div>
        </div>

        <div className="mx-10 space-y-10 md:flex md:justify-around">
          <div>
            <div>
              <img
                src={artDetail?.picture?.secure_url}
                alt="pic"
                className="w-full h-auto pt-10 mx-auto md:w-52"
              />
            </div>
            <div className="flex items-center justify-center mt-2 space-x-2">
              <div className="text-lg font-medium">Product Id</div>
              <div className="text-xl font-bold"> {id}</div>
            </div>
            {!buyOriginalType && (
              <div className="flex items-center justify-center my-2 space-x-2">
                <div className="text-lg font-medium">Product Price</div>
                <div className="text-xl font-bold">
                  USD
                  {(
                    priceToBeChekout +
                    priceToBeChekout * 0.09375 +
                    19.75
                  ).toFixed(2)}
                </div>
              </div>
            )}
            {!buyOriginalType && (
              <div className="px-3 py-1 bg-teal-200 rounded-lg">
                Including sales tax + Shipping and Handling charges
              </div>
            )}
          </div>

          {/* Buyer's details */}
          <div className="p-5 md:p-10 rounded-lg shadow-lg bg-slate-100 border w-full md:w-[30rem]">
            <div className="text-lg md:text-2xl ">Customer Details</div>
            <div className="py-6 space-y-3">
              <div>
                <div>Full Name</div>
                <input
                  onChange={(e) => {
                    setfullName(e.target.value);
                  }}
                  type="text"
                  className="w-full bg-transparent border-b border-slate-400 focus:outline-none"
                />
              </div>
              <div>
                <div>Address</div>
                <textarea
                  onChange={(e) => {
                    setaddress(e.target.value);
                  }}
                  type="text"
                  className="w-full bg-transparent border-b border-slate-400 focus:outline-none"
                />
              </div>
              <div>
                <div>Contact Number</div>
                <input
                  onChange={(e) => {
                    setcontactNumber(e.target.value);
                  }}
                  type="number"
                  className="w-full bg-transparent border-b border-slate-400 focus:outline-none"
                />
              </div>
              <div>
                <div>Contact Email</div>
                <input
                  onChange={(e) => {
                    setEmail(e.target.value);
                  }}
                  type="email"
                  className="w-full bg-transparent border-b border-slate-400 focus:outline-none"
                />
              </div>
            </div>
            {!buyOriginalType && (
              <div>
                <div className="flex items-center justify-between space-x-2">
                  <div className="text-sm font-medium">Product Price</div>
                  <div className="text-sm "> USD {priceToBeChekout}</div>
                </div>
                <div className="flex items-center justify-between py-2 space-x-2">
                  <div className="flex items-center space-x-3">
                    <div className="text-sm font-medium">Sales Tax</div>
                    <div className="text-sm text-stone-500 ">
                      <Tooltip
                        className="px-3 py-1 text-white bg-gray-700 rounded-sm"
                        label="9.375%"
                      >
                        <Button className="px-1 py-1 text-white bg-gray-700 rounded-full ">
                          <FontAwesomeIcon className="w-3 h-3" icon={faInfo} />
                        </Button>
                        {/* <CustomCard>Tag Here</CustomCard> */}
                      </Tooltip>
                    </div>
                  </div>
                  <div className="text-sm">USD {(priceToBeChekout * 0.09375).toFixed(2)}</div>
                </div>
                <div className="flex items-center justify-between pb-2 space-x-2">
                  <div className="text-sm font-medium">
                    Shipping and Handling Charges
                  </div>
                  <div className="text-sm "> USD 19.75</div>
                </div>

                <div className="flex items-center justify-between py-2 mb-5 space-x-2 border-t-2 border-black">
                  <div className="text-lg font-medium">Total Price</div>
                  <div className="text-xl font-bold">
                    {" "}
                    USD{" "}
                    {(
                      priceToBeChekout +
                      priceToBeChekout * 0.09375 +
                      19.75
                    ).toFixed(2)}
                  </div>
                </div>                
              </div>
            )}
            <div className="flex items-center justify-start pb-2 space-x-3">
              <div>Powered by</div>
              <img src={SquareLogoPoweredBy} alt="" className="w-16 h-auto text-left" />
            </div>
            {!buyOriginalType && (
              <PaymentForm
                /**
                 * Identifies the calling form with a verified application ID generated from
                 * the Square Application Dashboard.
                 */
                applicationId={process.env.REACT_APP_SQUARE_APP_ID}
                /**
                 * Invoked when payment form receives the result of a tokenize generation
                 * request. The result will be a valid credit card or wallet token, or an error.
                 */
                cardTokenizeResponseReceived={async (token, buyer) => {
                  console.info({ token, buyer });

                  const response = await fetch(
                    `${process.env.REACT_APP_BASE_URL}/art/payment?amount=${priceToBeChekout}`,
                    {
                      method: "POST",
                      headers: {
                        "Content-Type": "application/json",
                      },
                      body: JSON.stringify({
                        sourceId: token?.token,
                        price: (
                          priceToBeChekout +
                          priceToBeChekout * 0.09375 +
                          19.75
                        ).toFixed(2),
                      }),
                    }
                  );
                
                  console.log(response);
                  // dispatch({
                  //     type: "PAYMENT",
                  //     payload: {
                  //         body: {
                  //             sourceId: token?.token,
                  //         }
                  //     }
                  // })
                  if (response.status === 200) {
                    toast.success("Payment success");

                    alert(JSON.stringify(await response.json(), null, 2));
                  }
                }}
                /**
                 * This function enable the Strong Customer Authentication (SCA) flow
                 *
                 * We strongly recommend use this function to verify the buyer and reduce
                 * the chance of fraudulent transactions.
                 */

                /**
                 * Identifies the location of the merchant that is taking the payment.
                 * Obtained from the Square Application Dashboard - Locations tab.
                 */
                locationId="LTCZQNC840HDJ"
              >
                <CreditCard />
              </PaymentForm>
            )}
            {buyOriginalType && (
              <>
                <div
                  onClick={sendMailForOriginalArt}
                  className="w-full px-2 py-2 text-base text-center text-white uppercase bg-green-700 rounded-md cursor-pointer"
                >
                  Send Details
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ChekoutPage;
