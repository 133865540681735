import React from "react";
import guestbook01 from '../assets/guestbook01.jpg';
import guestbook02 from '../assets/guestbook02.jpg';
import guestbook03 from '../assets/guestbook03.jpg';
import guestbook04 from '../assets/guestbook04.jpg';
import guestbook05 from '../assets/guestbook05.jpg';
import guestbook06 from '../assets/guestbook06.jpg';
import guestbook07 from '../assets/guestbook07.jpg';
import guestbook08 from '../assets/guestbook08.jpg';
import guestbook09 from '../assets/guestbook09.jpg';
import guestbook010 from '../assets/guestbook010.jpg';
import Footer from "../components/Footer";
import Header from "../components/Header";


const Guestbook = () => {
    return (
        <>
        <div>
        <Header />
            <div className="px-5 py-20 space-y-10 md:px-10 lg:px-20">
                <h2 className="mb-5 text-3xl">Guest Book</h2>
                <div className="space-y-20 md:flex md:items-center md:space-x-5 md:space-y-0">
                    <div className="h-auto rounded-md shadow-lg w-full md:w-1/2 border-[10px] border-slate-100"><img src={guestbook01} alt='' /></div>
                    <div className="h-auto rounded-md shadow-lg w-full md:w-1/2 border-[10px] border-slate-100"><img src={guestbook02} alt='' /></div>
                </div>
                <div className="space-y-20 md:flex md:items-center md:space-x-5 md:space-y-0">
                    <div className="h-auto rounded-md shadow-lg w-full md:w-1/2 border-[10px] border-slate-100"><img src={guestbook04} alt='' /></div>
                    <div className="h-auto rounded-md shadow-lg w-full md:w-1/2 border-[10px] border-slate-100"><img src={guestbook05} alt='' /></div>
                </div>
                <div className="space-y-20 md:flex md:items-center md:space-x-5 md:space-y-0">
                    <div className="h-auto rounded-md shadow-lg w-full md:w-1/2 border-[10px] border-slate-100"><img src={guestbook03} alt='' /></div>
                    <div className="h-auto rounded-md shadow-lg w-full md:w-1/2 border-[10px] border-slate-100"><img src={guestbook06} alt='' /></div>
                </div>
                <div className="space-y-20 md:flex md:items-center md:space-x-5 md:space-y-0">
                    <div className="h-auto rounded-md shadow-lg w-full md:w-1/2 border-[10px] border-slate-100"><img src={guestbook07} alt='' /></div>
                    <div className="h-auto rounded-md shadow-lg w-full md:w-1/2 border-[10px] border-slate-100"><img src={guestbook08} alt='' /></div>
                </div>
                <div className="space-y-20 md:flex md:items-center md:space-x-5 md:space-y-0">
                    <div className="h-auto rounded-md shadow-lg w-full md:w-1/2 border-[10px] border-slate-100"><img src={guestbook09} alt='' /></div>
                    <div className="h-auto rounded-md shadow-lg w-full md:w-1/2 border-[10px] border-slate-100"><img src={guestbook010} alt='' /></div>
                </div>
            </div>
        <Footer />
        </div>
        </>
    )
};

export default Guestbook;