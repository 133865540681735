import { call, put, takeLatest } from "redux-saga/effects";
import { getToken, setErr } from "../redux/auth-slice";
import { accessTokenAction, loginAction } from "../api/authAction";

function* loginSaga(actions) {
  try {
    console.log(actions.payload, " <---- actions.payload.userLoginData auth ");
    const response = yield call(loginAction, actions.payload);

    console.log(response.access_Token, " <---- response auth ");
    if (response.success) {
      // const tokenResp = yield call(accessTokenAction);

      yield put(
        getToken({
          token: response?.access_Token,
        })
      );
    } else {
      yield put(setErr({ errmsg: response.message }));
    }
  } catch (error) {
    console.log(error, "error");
  }
}

export function* getTokenSaga() {
  const tokenResp = yield call(accessTokenAction);
  console.log(tokenResp, " <---- tokenResp auth ");
  yield put(getToken({ token: tokenResp.accessToken }));
}

export function* watchAuthAsync() {
  yield takeLatest("LOGIN_USER", loginSaga);
  yield takeLatest("GET_TOKEN", getTokenSaga);
}
