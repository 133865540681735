import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  token: null,
  userId: null,
  user: {},
  errmsg: "",
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    getToken(state, action) {
      state.token = action.payload.token;
    },
    logout(state) {
      state.token = null;
      state.userId = null;
    },
    setUser(state, action) {
      state.user = action.payload.user;
    },
    setErr(state, action) {
      state.errmsg = action.payload.errmsg;
    },
  },
});

export const { getToken, logout, setUser, setErr } = authSlice.actions;
export const authReducer = authSlice.reducer;
