import Header from "../components/Header";
import Footer from "../components/Footer";

const album_url =
  "https://res.cloudinary.com/dmfsbk8qn/image/upload/f_auto,q_auto/v1";

const Album = () => {
  const albumPhotos = [
    {
      photo: `${album_url}/album/bqsgpyffngavi0gn3zry`,
      text: "Artist & Phauzdar",
      alt: "",
    },
    {
      photo: `${album_url}/album/pnvhx4kpuxnofy0rs6c8`,
      text: "Phauzdar with art Critique Prashanta",
      alt: "",      
    },
    {
      photo: `${album_url}/album/kzijnk60m2rzuczfnqfj`,
      text: "Phauzdar at Art Gallery",
      alt: "",
    },
    {      
      photo: `${album_url}/album/zm3w3sipbj97vqi1birv`,
      text: "Director of Birla Academy",
      alt: "", 
    },
    {
      photo: `${album_url}/album/okqamestzm8qguxnbldv`,
      text: "Phauzdar with fans at Art Gallery",
      alt: "",
    },
    {
      photo: `${album_url}/album/mzkqfot9etdtfw27f73v`,
      text: "Phauzdar with artist Friend",
      alt: "",
    },
    {
      photo: `${album_url}/album/vpkizpey6tq8ok8msyyd`,
      text: "Local Viewers",
      alt: "",
    },
    {
      photo: `${album_url}/album/dkpfjmvvrmveq3tcltca`,
      text: "sculpturist & Phauzdar",
      alt: "",
    },
    {
      photo: `${album_url}/album/ildqpxb0fgvozvsdxgqt`,
      text: "Phauzdar with Art Lovers",
      alt: "",     
    },
    {
      photo: `${album_url}/album/mglbpvzaipp9l3qwlqwt`,
      text: "Phauzdar with Local Viewers",
      alt: "",
    },
    {
      photo: `${album_url}/album/otz4d3aipzrvywrw69mn`,
      text: "Viewers & Phauzdar",
      alt: "",
    },
    {
      photo: `${album_url}/album/ailtndx5iw7plfe2ka9w`,
      text: "Viewers at art Gallery",
      alt: "",
    },
    {
      photo: `${album_url}/album/lzybkkaayu0bekldkiwy`,
      text: "Mr. Jayanta (role model for Phauzdar) & Phauzdar",
      alt: "",
    },
    {
      photo: `${album_url}/album/klhdcyc1xrn5ol4spx6u`,
      text: "Phauzdar with his Fans at Art Gallery",
      alt: "",
    },
    {
      photo: `${album_url}/album/bdkxgczmfwfpzcjwznbi`,
      text: "Samol Baran Saha (Poet & Painter) with Phauzdar",
      alt: "",
    },
    {
      photo: `${album_url}/album/x4m5trlddlxenol7vmny`,
      text: "Local Artists & Phauzdar",
      alt: "",
    },
    {
      photo: `${album_url}/album/b8jb6wmqd0lpgzngzgcv`,
      text: "Viewers at Phauzdar's Art Gallery",
      alt: "",
    },
    {
      photo: `${album_url}/album/jpcnxxdnb9tgcaluhc8g`,
      text: "Phauzdar with viewers",
      alt: "",
    },
    {
      photo: `${album_url}/album/kaohy6be3zcmter4ydre`,
      text: "Phauzdar at art Gallery",
      alt: "",
    },
    {
      photo: `${album_url}/album/ccgjcr5jnvoxcxv0b8sm`,
      text: "Friend (artist) & Phauzdar",
      alt: "",
    },
    {
      photo: `${album_url}/album/owezhheujylzlegsakiq`,
      text: "Artist couple",
      alt: "",
    },
    {
      photo: `${album_url}/album/ijg2k36unde8flvwexr4`,
      text: "Viewers at Art Gallery",
      alt: "",      
    },
    {
      photo: `${album_url}/album/ecdakjrhq4qkezxf1j8a`,
      text: "Director of Birla Academy",
      alt: "",
    },
  ];

  return (
    <>
      <div className="">
        <Header />
        <div className="my-10 sm:mx-5 md:my-20 lg:mx-24 xl:mx-40">
          <h1 className="mx-5 mt-5 text-3xl font-thin text-left sm:mx-0 md:mt-10 text-slate-500">
            Phauzdar's Album!
          </h1>
          {/* <div className="grid p-5 mt-24 rounded-md bg-slate-100 md:grid-cols-2 lg:grid-cols-3 2xl:grid-cols-4 gap-x-5 gap-y-20"> */}
          <div className="p-5 mt-10 rounded-md md:mt-24 bg-slate-100 columns-1 sm:columns-1 md:columns-2 xl:columns-3 2xl:columns-4 gap-x-6 gap-y-20">
            {albumPhotos?.map((singleObj) => {
              return (
                <>
                  <div className="mb-10">
                    <div className="relative">
                      <img
                        src={singleObj.photo}
                        alt={singleObj.alt}
                        className="w-auto h-auto mt-1 border-8 border-white shadow-lg md:w-auto md:h-auto xl:w-auto xl:h-auto"
                      />
                      <div className="">
                        <div className="absolute w-full py-2 bg-black border-white opacity-50 bottom-2 border-x-8">
                          <h1 className="z-20 px-2 text-xl font-medium text-center text-white uppercase opacity-100 md:text-lg">
                            {singleObj.text}
                          </h1>
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              );
            })}
          </div>
        </div>
        <Footer />
      </div>
    </>
  );
};

export default Album;
