import React from "react";
import { BrowserRouter, Route, Routes as RoutePath } from "react-router-dom";
import LandingPage from "./pages/LandingPage";
import ArtDetailPage from "./pages/ArtDetailPage";
import AdminPage from "./pages/AdminPage";
import AboutUs from "./pages/AboutUs";
import Contact from "./pages/Contact";
import Album from "./pages/Album";
import Guestbook from "./pages/Guestbook";
import ChekoutPage from "./pages/ChekoutPage";
import BooksMedia from "./pages/BooksMedia";
import TermsAndCondition from "./pages/TermsAndCondition";
import Disclaimer from "./pages/Disclaimer";

const Routes = () => {
  return (
    <BrowserRouter>
      <RoutePath>
        <Route path="/" element={<LandingPage />} />
        <Route path="/:id" element={<ArtDetailPage />} />
        <Route path="/admin" element={<AdminPage />} />
        <Route path="/aboutus" element={<AboutUs />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/booksmedia" element={<BooksMedia />} />
        <Route path="/termsandcondition" element={<TermsAndCondition />} />
        <Route path="/disclaimer" element={<Disclaimer />} />
        <Route path="/album" element={<Album />} />
        <Route path="/guestbook" element={<Guestbook /> } />
        <Route path="/:id/checkout" element={<ChekoutPage />} />
      </RoutePath>
    </BrowserRouter>
  );
};

export default Routes;
