import { configureStore } from "@reduxjs/toolkit";
import createSagaMiddleware from "redux-saga";
import { rootSaga } from "../saga/rootSaga";
import { authReducer } from "./auth-slice";
import { artSliceReducer } from "./art-slice";

const sagaMiddleware = createSagaMiddleware();

const middleware = [sagaMiddleware];

const store = configureStore({
  reducer: {
    auth: authReducer,
    art: artSliceReducer,
  },
  middleware,
});

sagaMiddleware.run(rootSaga);

export default store;
